
.carousel .thumb{
    height: 60px;
    width: 100px;
}
.imgThubnailRoll
    {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        display: block;
      }
.carousel.carousel-slider .control-arrow:hover{
    background: none;
}
.menu-item > .sub-menu > a{
	text-decoration: none;
}
li {
    margin-left: 0px !important;
}
.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #f44646;
}
.carousel .control-next.control-arrow:before {
    border-left: 8px solid #ff2f2f;
}