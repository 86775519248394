.heading-top{
    margin-top: 20px;
}
h1,h2,h3,h4,h5,h6{
    margin-bottom: .5rem;
    font-weight: 700;
    line-height: 1;
}
.main-logo{
    height: 150px;
    float: left;
    margin-left: 20px;
    padding: 0;
    box-shadow: 0 0 10px black;
    border-radius: 50%;
    
}
.logo{
    color: red;
    text-align: center;
    margin: 0;
    font-size: 30px;
}

.sub-heading{
    text-align: center;
    font-size: 28px;
    color: red;
    
}
.sub-heading1{
    text-align: center;
    font-size: 18px;
    color:rgb(241, 177, 15)
}
.sub-heading2{
    text-align: center;
    font-size:23px ;
}
button:hover {
    border: none;
    color: black;
}