.overview-body-content {
    margin-left: 40px;
    font-size: 14px;
    font-size: 14px;
    text-transform: capitalize;

}
.faculty-name{
    font-size: 3rem;
    font-weight: bolder;
}
.overview-row1{
    border: 1px solid rgb(0, 0, 0);
    align-items: center;
    
}
.overview-col1{
    /* height: 50px; */
    font-weight: 900;
    font-size: 17px;
    color: black;
    background-color: antiquewhite;
    border: 1px solid black;
}

.data-col{
    border: 1px solid black;
    padding: 5px;
}