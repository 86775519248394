.place-IdCard{
    width:98%;
    margin: auto;
    box-shadow: 0 0 10px #f8b153;
}
.prof-title{
    font-size: 2rem;
    color: #ee7c7c;
    padding: 10px;
}
.more-content{
    font-weight: bold;
    text-decoration: none;
    color: black;
}
 a:hover{
    color: #0e0d0d;
    cursor: pointer;
    text-decoration: none;
}
.idcard-details{
    text-align: justify;
    padding: 10px;
    font-size: 13px;
}
.employeecard-image-3{
    border-radius: 0;
}
.employeecard-desk {
    background: white;
    margin:10px auto;
    height: 150px;
    background-size: 160px 160px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: 3px solid #565656;
    width: 150px;
    border-radius: 5px;
}