/* --------------------First Page CSS------------------------ */
.table-admission{
    font-size: 1rem;
}
.add-label{
    font-size: 2rem;
    padding: 10px;
    text-align: center;
}
.Header-content{
    background-color: rgba(112, 153, 42, 0.616);
    padding: 10px;
}
.input-full{
    border: none;
    border-bottom: 2px solid black;
    padding-top: 10px;
    outline: none;
}
.admission{
    background-color: #a4c561;
}

.letter{
    margin-left: 100px;
    font-size: 15px;
}
.type-of{
    font-size:2rem ;
}

.header_content  h2{
       margin-top: -1rem;        
}