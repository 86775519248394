@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

body{
	line-height: 1.5;
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
}
body.hidden-scrolling{
	overflow-y: hidden;
}
*{
	margin:0;
	box-sizing: border-box;
}
ul{
	list-style: none;
	margin:0;
	padding:0;
}
.menu-item > .sub-menu > a{
	text-decoration: none;
}
li {
    font-size: 2rem;
}
/*header*/


.menu-item:hover{
	background-color: rgba(117, 114, 114, 0.5);
}

.menu-item{
	display: inline-block;
	position: relative;
}
.menu-item > a{
	display: block;
	padding: 12px 0 5px 5px;
	font-size: 16px;
	color: #000000;
	text-transform: capitalize;
	font-weight: 600;
	transition: all 0.3s ease;
	text-decoration: none;
}
.spaceTopMenu{
	margin-right: 30px;
}
.menu-item > a .plus:before,
.menu-item > a .plus:after{
	content:'';
	position: absolute;
	box-sizing: border-box;
	left: 50%;
	top:50%;
	background-color: #000000;
	height: 2px;
	width: 100%;
	transform: translate(-50%,-50%);
	transition: all 0.3s ease;
}
/* .menu-item > a .plus:after{
   transform: translate(-50%,-50%) rotate(-90deg);	
} */
.menu-item > .sub-menu > .menu-item > a:hover,
.menu-item:hover > a{
	color: orangered;
}
.menu-item > .sub-menu > .menu-item > a{
	color: #000000;
	text-align: center;
}

.menu-item > .sub-menu{
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
	width: 200px;
	position: absolute;
	left:0;
	top:100%;
	background-color: #ffffff;
	padding: 10px 0;
	transform: translateY(10px);
	transition: all 0.3s ease;
	opacity:0;
	visibility: hidden;
}

.menu-item > .sub-menu > .menu-item{
	display: block;
}
.dropdown-content{
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.showPopup{
	display: block;
}
.noShowPopup{
	display: none;
}
.menu-item > .sub-menu > .menu-item > a{
	display: block;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 600;
	color: #000000;
	transition: all 0.3s ease;
	text-transform: capitalize;
}

/* responsive */

@media(max-width: 991px){
	.header .menu-overlay.active{
	visibility: visible;
	opacity: 1;
}
	.header .nav-menu{
		position: fixed;
		right: -280px;
		visibility: hidden;
		width: 280px;
		height: 100%;
		top:0;
		overflow-y: auto;
		background-color: white;
		z-index: 1000;
		padding: 15px 0;
		transition: all 0.5s ease;
	}
	.header .nav-menu.open{
		visibility: visible;
		right: 0px;
	}
	.header .menu > .menu-item{
		display: block;
		margin:0;
		color: #000000;
	}
	.menu-item-has-children > a{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
.menu-item > a{
		color: black;
		/* padding: 12px 15px; */
		/* border-bottom: 1px solid #333333; */
	}
	.header .menu > .menu-item:first-child > a{
	    border-top: 1px solid #333333;	
	}
	
	/* .header .menu > .menu-item > a .plus:before, 
	.header .menu > .menu-item > a .plus:after{
		background-color: black;
	} */
	/* .header .menu > .menu-item-has-children.active > a .plus:after{
        transform: translate(-50%,-50%) rotate(0deg);
	} */
	.header .menu > .menu-item > .sub-menu{
		width: 100%;
		position: relative;
		opacity: 1;
		visibility: visible;
		border:none;
		background-color: transparent;
		box-shadow: none;
		transform: translateY(0px);
		padding: 0px;
		left: auto;
		top:auto;
		max-height: 0;
		overflow: hidden;
	}
	.header .menu > .menu-item > .sub-menu > .menu-item > a{
		padding: 12px 45px;
		color: black;
		border-bottom: 1px solid #333333;
	}
	.header .close-nav-menu,
	.header .open-nav-menu{
		display: flex;
	}
}

