.brand-name{
    height: 100px;
    transition: transform .2s;
    padding-left: 10px;
}
.brand{
    display: flex;
}
.png-logo{
   position:absolute;
   top: 0;
   right: 5%;
   padding: 10px;
   animation: logomove 5s  ;
   animation-direction: alternate-reverse; 
}
.brand-name:hover{
    transform: scale(1.1); 
}
@keyframes logomove {
    from {right: 50px;}
    to {right: 200px;}
  }
