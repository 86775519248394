.img-card{
    margin: auto;
    height: 400px;
    width: 600px;
}

.slider-content{
    width: 650px;
    height: 450px;
    margin: auto;
}
.slick-next{
    margin: -15px;
}
.slick-prev{
    margin: -15px;

}
