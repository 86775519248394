@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

                    body {
                        font-family: 'Montserrat', sans-serif;
                    }

.card{
    width: 300px;
    background-color: white;
    border-radius: 10px;
    /* box-shadow: 0px 1px 10px 1px black; */
    overflow: hidden;
   
}
.cardEmployee{
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 1px 7px 1px #565656;
    overflow: hidden;
    margin: 3px;
   
}
.colEmployeeCard{
    padding:3px;
}

.upper-container{
    height: 100px;
    background:linear-gradient(to left ,#211ed4,#102a42);
}

.employeecard-image {
    background: white;
    margin:10px auto;
    height: 80px;
    background-size: 90px 90px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: 3px solid #565656;
    width: 80px;
    border-radius: 40px;
}


.employeecard-image-2 {
    margin: 10px 20px;
    height: 140px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 0px solid #565656;
    width: 100px;
}

.lower-container{
    height: 300px;
    background: white;
    text-align: center;
    margin-top: 60px;
    font-family: 'Titillium Web',sans-serif;
    margin-top: 8rem;
}

.lower-container h3,h4,p{
    margin: 0;
    padding: 0;
}

.lower-container h3{
    color: #2f2d2e;
    font-weight: 600;
    font-size: 1.5rem;

}

.lower-container h4{
    color: #8e00b9;
    font-weight: 700;
    font-size: 1.2rem;
    margin-top: 1.5rem;
}
.lower-container p{
    font-family: sans-serif;
    color: #211ed4;
    margin: 1rem;
    font-size: 0.9rem;
    text-align: center;
}

button{
    
    color: #2f2d2e;
    font: bold;
    background:#565656;
    /* border: 2px solid #211ed4; */
    padding: 0.9rem;
    margin-top: 5rem;
    border-radius: 6px;
    transition-duration: 0.5s;
    -webkit-animation: ease-in-out;
            animation: ease-in-out;
}

button:hover{
    /* background-image: linear-gradient(to left, #211ed4,#102a42); */
    /* transform: scale(1.2); */
    border: none;
    color: white;
    
}
    .colHome{
        padding:0px !important;
    }


                    /* Carousel Style */
                    
                    .slider-overlay {
                        position: absolute;
                        height: 100%;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: #000000;
                        opacity: 0.1;
                        color: #fff;
                    }
                    /* Section Title */
                    
                    .banner-caption {
                        bottom: 28%;
                    }
                   
                    
                    .banner-caption h1 {
                        font-size: 53px;
                    }
                    
                    .banner-caption h2 {
                        font-size: 27px;
                    }
                    
                    .service-icon {
                        color: red;
                        height: 100px;
                        margin-top: 20px;
                    }
                    
                    .why-choose-icon {
                        color: red;
                        height: 75px;
                        margin-top: 20px;
                    }
                    .why-choose-icon-2 {
                        color: red;
                        height: 75px;
                        margin-top: 20px;
                        position: absolute;
                        bottom: 95%;
                        background-color: #FFF;
                        left: 30%;
                        width: 40%;
                    }
                    .why-choose-card-2 .card-body{
                        border: 1px solid #dcdcdc;
                    }
                    .card-margin-2 {
                        margin: 15px 0px;
                        margin-bottom: 80px;
                    }
                    
                    svg {
                        color: black;
                    }
                    
                    .brandname {
                        font-size: 26px;
                    }
                    
                    .nav-element {
                        font-size: 17px;
                        line-height: 41px;
                        margin: 0px 15px;
                    }
                    
                    .companyname {
                        font-size: 18px;
                        font-weight: bold;
                    }
                    
                    .brand-logo {
                        height: 120px;
                    }
                    
                    .nav-bg {
                        background-color: #fff;
                    }
                    

                    .slider-package-info {
                        position: absolute;
                        /*top: 0px;*/
                        bottom: 15px;
                        left: 18%;
                    }
                    
                    .slider-package-info h3 {
                        font-size: 35px;
                    }
                    
                    .carousel-height {
                        height: 50rem;
                    }
                    
                    .carousel-img {
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        height: 50rem;
                        object-fit: cover;
                    }
                    
                    .banner-caption .btn-outline-dark {
                        color: #fff;
                        border-color: #fff;
                        font-size: 16px;
                        padding: 10px 30px;
                    }
                    
                    .banner-caption .btn:hover {
                        background-color: #414141;
                    }
                    /*carousel btn */
                    
                    .btn-default.btn2 {
                        font-family: 'Montserrat';
                        display: inline-block;
                        color: #fff;
                        border: none;
                        font-size: 11px;
                        line-height: 20px;
                        font-weight: 700;
                        padding: 8px 20px;
                        text-decoration: none;
                        border-radius: 0px;
                        -moz-border-radius: 0px;
                        -webkit-border-radius: 0px;
                        margin-top: 0px;
                        margin-bottom: 0px;
                        text-transform: uppercase;
                        background: none;
                        position: relative;
                        border: 2px solid #fff;
                        letter-spacing: 1px;
                    }
                    
                    .btn-default.btn2:hover {
                        color: #fff;
                        background: #565656;
                        border: 2px solid #565656;
                    }
                    
                    .btn-default.btn3 {
                        font-family: 'Montserrat';
                        display: inline-block;
                        color: #fff;
                        border: none;
                        font-size: 9px;
                        line-height: 25px;
                        font-weight: 700;
                        padding: 0;
                        text-decoration: none;
                        border-radius: 0px;
                        border-radius: 5px;
                        margin-top: 20px;
                        margin-right: 10px;
                        /* margin-bottom: 0px; */
                        text-transform: uppercase;
                        background: #565656;
                        position: relative;
                        border: 2px solid #565656;
                        letter-spacing: 1px;
                        width: 100%;
                    }
                    .btn-default.btn4 {
                        width: 50%;
                        margin-bottom: 10px;
                    }
                    
                    .btn-default.btn3:hover {
                        
                        background: #CFCFCF;
                        border: 2px solid #CFCFCF;
                        color:#000000;
                    }
                    
                    .btn-space {
                        margin-top: 20px;
                    }
                    
                    .title {
                        text-align: center;
                        color: #35373e;
                        font-weight: 700;
                        font-family: 'Montserrat';
                        font-size: 22px;
                        line-height: 1;
                        text-transform: uppercase;
                        padding-top: 20px;
                        padding-bottom: 5px;
                        /* margin-bottom: 15px; */
                        letter-spacing: 2px;
                    }
                    
                    .product-overview-title {
                        text-align: center;
                        color: #35373e;
                        font-weight: 700;
                        font-family: 'Montserrat';
                        font-size: 22px;
                        line-height: 1;
                        text-transform: uppercase;
                        padding-bottom: 20px;
                        margin-bottom: 15px;
                        letter-spacing: 2px;
                    }
                    .product-overview-title2 {
                        text-align: center;
                        color: #35373e;
                        font-weight: 700;
                        font-family: 'Montserrat';
                        font-size: 22px;
                        line-height: 1;
                        text-transform: uppercase;
                        padding-bottom: 0px;
                        margin-bottom: 0px;
                        letter-spacing: 2px;
                    }
                    
                    .product-overview-row {
                        display: -webkit-flex;
                        display: flex;
                    }
                    
                    .sidebar-item {
                        /* position: absolute; */
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    
                    .make-me-sticky {
                        position: -webkit-sticky;
                        position: sticky;
                        top: 10%;
                        padding: 0px;
                    }
                    
                    .section-top-padding {
                        padding-top: 50px;
                    }
                    
                    .section-bottom-padding {
                        padding-bottom: 50px;
                    }
                    
                    .row{
                        margin: 0px;
                        padding: 0px;
                    }    
                    
                .background-img {
                    position: relative;
                    width: 100%;
                    display: block;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                }
                .background-img:before {
                    position: absolute;
                    content: "";
                    background: rgb(178, 45, 48, .85);
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
                    
                    .about-services {
                        text-align: center;
                        color: #777777;
                        font-size: 19px;
                        margin-bottom: 15px;
                    }
                    
                    .product-description-p {
                        text-align: left;
                        color: #777777;
                        font-size: 17px;
                        margin-bottom: 50px;
                    }
                    .product-sub-p{
                        font-weight: 600;
                    }
                    
                    .productcard-image {
                        height: 230px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        border-bottom: 1px solid rgba(0, 0, 0, .125);
                    }
                    /* .employeecard-image {
                        height: 100px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        border-bottom: 1px solid rgba(0, 0, 0, .125);
                        width: 100px;
                        border-radius: 50px;
                        margin-left: 90px;
                       
                        
                    } */

                    /* --------------Table---------------------------- */
                    .table-cont{
                        /* width: 900px; */
                        /* float: right; */
                        margin-left: 20px;
                        /* height: 500px; */
                    }
                    .bg-color{
                        background-color: rgba(129, 161, 72, 0.404);
                    }
                    .para-name{
                        font-size: 2rem;
                    }
                    
                    .td-data{
                        width: 150px !important;
                    }
                    .table td{
                        border:1px solid black;
                        font-size: 12px;
                    }
                    .table th{
                        border:1px solid black;
                        text-align: center;
                        font-size: x-large;

                    }
                    
                    .table-col{
                        -webkit-flex-grow: none;
                                flex-grow: none;
                        text-align: center;
                        margin-right: 200px;
                        padding: 20px;
                    }
                    .table-col h1{
                        font-size: 4rem;
                    }
                    
                    .productcard-title {
                        font-size: 20px;
                        color: #141414;
                        
                    }
                    
                    .why-choose-title {
                        font-size: 25px;
                        color: #141414;
                    }
                    
                    .productcard-paragraph {
                        font-size: 15px;
                        color: #999999;
                        line-height: 1.6666667;
                        height: 70px;
                    }
                    
                    .why-choose-card {
                        background-clip: border-box;
                        border: none;
                    }
                    
                    .why-choose-paragraph {
                        font-size: 15px;
                        color: #999999;
                        line-height: 1.6666667;
                    }
                   
                    
                    .card-content {
                        margin-top: 15px;
                       
                    }
                    
                    /* .why-choose-content {
                        margin: 10px 0px;
                    }
                     */
                    .card-margin {
                        margin: 15px 0px;
                        
                        
                    }
                    
                    .light-background {
                        background: #f7f8f9;
                    }
                    
                    .view-all-txt {
                        text-align: center;
                        color: #000;
                        font-size: 19px;
                        margin-top: 15px;
                    }
                    
                    .view-all-txt a {
                        color: #000;
                        text-decoration: none;
                    }
                    
                    .contact-info-h3 {
                        margin: 0 0 30px 0;
                        font-size: 32px;
                        line-height: 32px;
                    }
                    
                    .contact-content {
                        clear: both;
                        padding: 25px 0;
                        border-bottom: 1px solid #eee;
                    }

                    .gst-section .contact-content{
                        border-bottom: none;
                    }
                    
                    .contact-content>.icon {
                        position: relative;
                        display: block;
                        float: left;
                        font-size: 40px;
                        line-height: 40px;
                        font-weight: normal;
                        margin-right: 20px;
                        text-align: center;
                        cursor: default;
                        color: #565656;
                    }
                    
                    .contact-content>.contact-text {
                        position: relative;
                        display: block;
                        float: left;
                        width: calc(100% - 60px);
                    }
                    
                    .contact-content>.contact-text>h4 {
                        margin: 0;
                        font-size: 23px;
                        margin-bottom: 10px;
                        transition: all 0.3s;
                    }
                    
                    .contact-content>.contact-text>p {
                        margin: 0;
                    }
                    
                    .contact-content p {
                        font-size: 16px;
                    }
                    
                    .contact-content a {
                        color: #000;
                        text-decoration: none;
                    }
                    
                    .mb-40 {
                        margin-bottom: 40px !important;
                    }
                    
                    .contact input,
                    .contact select {
                        height: 50px;
                        text-indent: 10px;
                        font-size: 14px;
                    }
                    
                    .contact input,
                    .contact textarea,
                    .contact select {
                        width: 100%;
                        border: 1px solid #666;
                        margin-bottom: 3px;
                        font-size: 14px;
                    }
                    
                    
                    .contact-p{
                   
                        /* text-align: center; */
                        color: #777777;
                        font-size: 16px;
                        margin-bottom: 15px;

                    }

                    .category-p{
                   
                        
                        color: #777777;
                        font-size: 16px;
                        margin-bottom: 15px;

                    }
                    .category-detail-m{
                        display: none;
                    }

                    .btn4{
                        margin-left: 15px;
                        font-weight: 600;
                        color: #565656;
                    }

                    .btn4:hover{
                        
                        color: #000000;
                        text-decoration: none;
                    }

                    .text-strong{
                        font-weight: 500;
                    }

                    a,
                    button,
                    input {
                        text-decoration: none;
                        /* transition: 0.4s; */
                    }
                    
                    .help-block {
                        display: block;
                        margin-top: 5px;
                        margin-bottom: 10px;
                        color: #737373;
                    }
                    
                    .contact textarea {
                        resize: vertical;
                        padding: 10px;
                    }
                    
                    .contact input,
                    .contact textarea,
                    .contact select {
                        width: 100%;
                        border: 2px solid #565656;
                        margin-bottom: 3px;
                    }
                    
                    .get-in-touch-top-padding {
                        padding-top: 15px;
                    }
                    .section-title-border.center {
                        margin-right: auto;
                        margin-left: auto;
                        padding-top: 0px;
                        height: 3.5px;
                    }
                    .section-title-border {
                        width: 50px;
                        height: 2px;
                        margin-bottom: 30px;
                        background-color: #565656;
                    }
                    .margin-top-20 {
                        margin-top: 10px;
                    }
                    /* Footer */
                    
                    .sec-padding {
                        padding: 60px 0 60px 0;
                    }
                    
                    .section-dark {
                        background-color:#414141;
                    }
                    
                    .fo-map {
                        background-size: 100%;
                    }
                    
                    .fo-map {
                        width: 100%;
                        float: left;
                    }
                    
                    .footer-logo {
                        margin-bottom: 35px;
                    }
                    
                    .text-light {
                        color: #acacac;
                        font-size: 14px;
                    }
                    
                    .font-weight-5 {
                        font-weight: 500;
                    }
                    
                    .text-white {
                        color: #fff;
                    }
                    
                    .section-dark h4 {
                        font-size: 22px;
                        line-height: 25px;
                        margin-bottom: 18px;
                        font-style: normal;
                    }
                    
                    .footer-quick-links-4 {
                        width: 100%;
                        padding: 0 0 0 0;
                        margin: 0px;
                        font-size: 14px;
                    }
                    
                    ul {
                        list-style-type: none;
                    }
                    
                    .footer-quick-links-4 li {
                        width: 100%;
                        padding: 0px;
                        margin: 0;
                        display: inline-block;
                    }
                    
                    .footer-quick-links-4 li a {
                        padding: 3px 0;
                        margin: 0;
                        color: #ffffff;
                        text-decoration: none;
                        float: left;
                        display: inline-block;
                    }
                    
                    .footer-quick-links-4 li a i {
                        padding: 0 10px 0 0;
                    }
                    
                    .margin-bottom {
                        margin-bottom: 0px;
                    }
                    
                    .footer-social-icons.left-align {
                        text-align: left;
                    }
                    
                    .footer-social-icons {
                        width: 100%;
                        padding: 0px;
                        margin: 15px 0 15px 0;
                    }
                    
                    .footer-social-icons li {
                        padding: 0px;
                        margin: 0px;
                        display: inline-block;
                    }
                    
                    .footer-social-icons.icons-plain li a {
                        background: none;
                    }
                    
                    .footer-social-icons.icons-plain li a {
                        background: none;
                    }
                    
                    .footer-social-icons li a {
                        width: 36px;
                        height: 36px;
                        text-align: center;
                        line-height: 40px;
                        display: inline-block;
                        padding: 0px;
                        color: #fff;
                        background-color:#414141;
                    }
                    
                    .sec-padding-6 {
                        padding: 30px 0 30px 0;
                    }
                    
                    .section-medium-dark {
                        background-color:#565656;
                    }
                    
                    .fo-copyright-holder {
                        position: relative;
                        width: 100%;
                        float: left;
                        padding: 0px;
                        margin: 0px;
                        font-weight: normal;
                        font-style: normal;
                        line-height: 23px;
                        color: #fff;
                        font-size: 15px;
                    }
                    .footer-strong{
                        font-weight: bold;
                    }
                    .footer-strong a{
                        color: #fff;
                        
                    }
                    .footer-strong a:hover{
                        text-decoration: none;
                        
                    }
                    /* Footer */
                    /*SearchComponent*/
                    
                    .find-flight-form .form-group,
                    .find-hotel-form .form-group,
                    .find-tour-form .form-group,
                    .find-car-form .form-group {
                        position: relative;
                    }
                    
                    .form-group label {
                        font-weight: bold;
                        font-size: 16px;
                    }
                    
                    input.search-input {
                        border-radius: 0px;
                        height: 50px;
                        position: relative;
                        border: 2px solid #565656;
                        font-size: 15px;
                        padding-left: 15px;
                        box-shadow: 0 15px 80px #e2e2e2;
                    }
                    
                    .search-icon {
                        position: absolute;
                        width: 45px;
                        height: 45px;
                        text-align: center;
                        line-height: 45px;
                        font-size: 3rem !important;
                        right: 30px;
                        top: 12px;
                        color: #565656;
                    }
                    /* textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
} */
                    
                    .search-input:focus {
                        border: 2px solid #000000;
                        border-radius: 0px;
                        box-shadow: none;
                    }
                    
                    .searchbox {
                        display: -webkit-flex;
                        display: flex;
                        -webkit-justify-content: center;
                                justify-content: center;
                    }
                    
                    .category-name {
                        font-size: 19px;
                        font-weight: 500;
                        color: #000000;
                        text-transform: uppercase;
                        margin: 0px 20px;
                    }
                    
                    .category-name:hover {
                        color: #7c7c7c;
                    }
                    
                    .category-group {
                        margin-bottom: 20px;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-justify-content: center;
                                justify-content: center;
                    }
                    
                    .search-component {
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }
                    /*SearchCOmponent End*/
                    
                    .product-details-img.product-details-tab {
                        display: -webkit-flex;
                        display: flex;
                        -webkit-flex-direction: column;
                                flex-direction: column;
                    }
                    
                    .slick-track-1 {
                        opacity: 1;
                        width: 1536px;
                    }
                    
                    .product-details-img.product-details-tab .zoompro-wrap.zoompro-2 {
                        display: inline-block;
                        position: relative;
                        width: 100%;
                        float: left;
                        /* overflow: hidden; */
                    }
                    
                    .slick-slider {
                        position: relative;
                        display: block;
                        box-sizing: border-box;
                        -webkit-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        -webkit-touch-callout: none;
                        -khtml-user-select: none;
                        touch-action: pan-y;
                        -webkit-tap-highlight-color: transparent;
                    }
                    
                    .slick-slider .slick-track,
                    .slick-slider .slick-list {
                        -webkit-transform: translate3d(0, 0, 0);
                        transform: translate3d(0, 0, 0);
                    }
                    
                    .slick-track {
                        position: relative;
                        top: 0;
                        left: 0;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    
                    .slick-initialized .slick-slide {
                        display: block;
                    }
                    
                    .slick-slide {
                        display: none;
                        float: left;
                        height: 100%;
                        min-height: 1px;
                    }
                    
                    .product-details-img.product-details-tab .zoompro-wrap.zoompro-2 .zoompro-border.zoompro-span img {
                        width: 100%;
                    }
                    
                    .slick-track {
                        position: relative;
                        top: 0;
                        left: 0;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    
                    element.style {
                        width: 144px;
                    }
                    
                    .product-dec-slider-2 .single-slide-item {
                        margin: 0 10px;
                        transition: all .3s linear;
                    }
                    
                    .slick-slide img {
                        display: block;
                    }
                    
                    .img-responsive {
                        max-width: 100%;
                        display: block;
                    }
                    
                    .slick-track:after {
                        clear: both;
                    }
                    
                    .slick-track:before,
                    .slick-track:after {
                        display: table;
                        content: '';
                    }
                    
                    .product-details-img.product-details-tab .zoompro-wrap.zoompro-2 .zoompro-border.zoompro-span img {
                        width: 100%;
                    }
                    
                    .slick-track-2 {
                        opacity: 1;
                        -webkit-transform: translate3d(0px, 0px, 0px);
                                transform: translate3d(0px, 0px, 0px);
                    }
                    
                    .img-responsive {
                        max-width: 100%;
                        display: block;
                    }
                    
                    .product-dec-slider-2 .single-slide-item.slick-slide.slick-current img {
                        border: 1px solid #ea1b25;
                    }
                    
                    .product-dec-slider-2 .single-slide-item img {
                        margin: auto;
                        transition: all .3s linear;
                        border: 1px solid #e5e5e5;
                    }
                    
                    .slick-list {
                        position: relative;
                        display: block;
                        /* overflow: hidden; */
                        margin: 0;
                        padding: 0;
                    }
                    
                    .product-dec-slider-2 {
                        margin-top: 20px;
                    }
                    
                    .product-details-img.product-details-tab .zoompro-wrap.zoompro-2 {
                        display: inline-block;
                        position: relative;
                        width: 100%;
                        float: left;
                        /* overflow: hidden; */
                    }
                    
                    .product-details-img.product-details-tab {
                        display: -webkit-flex;
                        display: flex;
                        -webkit-flex-direction: column;
                                flex-direction: column;
                    }
                    
                    .product-banner-image {
                        height: 100vh;
                        width: 100%;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    
                    .product-detail-thumbnail {
                        height: 90px;
                        width: 90px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        margin: 20px 10px;
                    }
                    
                    .product-detail-thumbnail-group {
                        display: -webkit-flex;
                        display: flex;
                        -webkit-flex-direction: row;
                                flex-direction: row;
                        -webkit-justify-content: center;
                                justify-content: center;
                    }
                    
                    .description-section {
                        background-color: #f6f6f6;
                        /* height: 100%; */
                    }
                    
                    .product-overview-sticky {
                        position: -webkit-sticky;
                        position: sticky !important;
                        top: 0;
                    }
                    
                    .product-overview-col {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                    
                    .product-img-col {
                        position: -webkit-sticky;
                        position: sticky;
                        top: 0;
                        z-index: 1019;
                    }
                    
                    .product-detail-container {
                        padding: 50px;
                    }
                    
                    .product-overview-title {
                        padding-top: 0px;
                    }
                    
                    .product-overview-row {
                        padding: 0px;
                        margin: 0px;
                    }
                    
                    .form-control {
                        border-radius: 0px;
                    }
                    
                    .form-control:focus {
                        border-color: #000000;
                        box-shadow: 0 0 0 0.2rem rgba(65, 65, 65, 0.25);
                        border: 2px solid;
                        border-radius: 0px;
                    }
                    
                    body,
                    tbody,
                    tbody td {
                        background-color: transparent;
                    }
                    
                    .serviceContaints p {
                        font-size: 1.3rem;
                    }
                    
                    .serviceContaints span {
                        font-size: 1.5rem;
                    }
                    
                    .serviceContaints ul {
                        margin-left: 3rem;
                    }
                    
                    .serviceContaints li {
                        list-style: square;
                        padding-left: 1rem;
                    }

                    .product-overview-thumbnail{
                        width: 100%;
                    }

                    .serviceContaints li{
                        font-size: 18px;
                        line-height: 35px;
                    }
                    .serviceContaints span{
                        font-size: 18px;
                        line-height: 50px;
                        font-weight: 500;
                    }
                    .serviceContaints p{
                        font-size: 18px;
                        line-height: 40px;
                        margin-top: 20px;
                        font-weight: 500;
                    }

                    .serviceContaints{
                        margin-bottom: 50px;
                    } 
                    
                    .go2646822163 {
                        height: 50px;
                        text-indent: 10px;
                        font-size: 14px;
                        border-radius: 0px !important ;
                        border: 1px solid #666 !important;
                        font-size: 14px !important;
                        color: #666 !important;
                        padding-top: 5px;
                    }

                    .go2139093995 .gray {
                        color: #666 !important;

                    }

                    .go2646822163:focus-within {
                        box-shadow: unset !important;
                        border-color: #000 !important;
                    }
                    .go3425554998{
                        padding-top:5px !important ;
                    }
                    .go3425554998 .panel-content{
                        max-height: 200px !important;
                        margin: -3px !important;
                    }

                    .contact input, .contact textarea, .contact select:focus {
                        box-shadow: unset !important;
                        
                        border-radius: 0px !important;
                    }

                     .contact select:active{
                        
                        border-radius: 0px !important;
                    }

                    .item-renderer{
                        font-size: 14px;
                    }
                    .item-renderer input{

                        width: unset;
                        height: unset;
                        font-weight: normal;
                    }
                    .item-renderer span{
                        font-weight: normal;
                    }
                    .go2343601875{
                        border: 0px solid #fff !important;
                    }

                    .titleContactInfo{
                        text-align: center;
                        color: #777777;
                        font-size: 19px;
                        margin-bottom: 15px;
                    }
                    .dropdown-heading-dropdown-arrow {
                        height: 16px !important;
                        margin-right: 0px;
                        margin-left: 0px;
                        font-size: 13px !important;
                        z-index: 400 !important;
                    }
                    .dropdown-heading{
                        padding-left: 0px;
                        padding-right: 0px !important;
                        padding-bottom: 2px !important;
                        z-index: 400 !important;
                    }

                    .dropdown-heading-dropdown-arrow{
                        padding-top: 0px !important;
                        padding-bottom: 0px!important;
                        margin-top: 0px !important;
                        margin-bottom: 0px !important;
                    }


                    .navbar-light .navbar-nav .nav-link{
                        color: #000000;
                        padding-bottom: 5px;
                        padding-top: 0px;
                        margin-top: 0px;
                        border: none;
                    }

                    /* .navbar-light .navbar-nav .nav-link:hover{
                       border-bottom: 3px solid #414141;
                    }

                    .navbar-light .navbar-nav .nav-link:active{
                        border-bottom: 3px solid #414141;
                     }
                     .navbar-light .navbar-nav .nav-link:focus{
                        border-bottom: 3px solid #414141;
                     }
  */

                    .menufixed {
                        
                        background-color: #fff;
                        box-shadow: 0 10px 15px rgb(0 0 0 / 5%);
                        top: 0;
                    }

                    .card-shadow{
                        box-shadow: 0 15px 80px #f1f1f1;
                        border: 1.4px solid #565656;
                        width: 300px;
                        height: 350px;
                      
                    }
                    /* .card-shadow:hover{
                        box-shadow: 0 25px 50px 0 rgb(33 30 30 / 20%);
                    } */
                   
                    #Shape{
                        fill:green;
                    }

                    .title-tech {
                        /* text-align: center; */
                        color: #000;
                        font-weight: 600;
                        /* font-family: 'Montserrat'; */
                        font-size: 31px;
                        line-height: 1;
                        /* text-transform: uppercase; */
                        padding-top: 0px;
                        padding-bottom: 20px;
                        /* margin-bottom: 15px; */
                        letter-spacing: 2px;
                    }
                    .title-tech2 {
                        /* text-align: center; */
                        color: #fff;
                        font-weight: 600;
                        /* font-family: 'Montserrat'; */
                        font-size: 31px;
                        line-height: 1;
                        /* text-transform: uppercase; */
                        padding-top: 0px;
                        padding-bottom: 20px;
                        /* margin-bottom: 15px; */
                        letter-spacing: 2px;
                    }


                    .about-nsak-section-p{
                        font-size: 23px;
                    text-transform: none;
                    color: #000;

                    padding-bottom: 15px;
                    }

                    .about-nsak-section-p-2{
                        font-size: 19px;
                        font-weight: 500;
                        text-transform: none;
                        color: #000;
                        padding-bottom: 15px;
                    }
                    .readmore-container{
                        padding-bottom: 30px;
                    }
                    .readmore-button{
                        font-size: 16px;
                    color: #000;
                    padding: 0px 0px 0px 0px;
                    text-transform: none;
                    border-radius: 0px;
                    -moz-border-radius: 0px;
                    -webkit-border-radius: 0px;
                    }
                    .readmore-transparent{
                        background: transparent;
                    }
                    
                    .readmore-button, .readmore-content{
                        display: inline-block;
                          vertical-align: middle;
                    }
                    
                    .readmore-container .MuiSvgIcon-root{
                        
                        margin-left: 8px;
                    }
                    .readmore-content{
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .news-element{
                        color: #fff;
                        font-size: 16px;
                        font-weight: 600;
                        padding: 10px;
                        
                    }
                    .news-border{
                        border-color: #53607a;
                        border-bottom-width: 1px !important;
                        border-bottom-style: solid;
    
                    }
                    
                    .plr{
                        padding-left: 90px;
                        padding-right: 90px;
                        padding-bottom: 90px;
                    }
                    .plr2{
                        padding-left: 60px;
                        padding-right: 60px;
                    }
                    .m-height{
                        height: 30rem;
                        width: 40rem;
                    }
                    /* .news-section{
                        height: 400px;
                    } */
                    /*Media Queries */
                    
                    @media (max-width: 992px) {
                        .banner-caption h2 {
                            font-size: 25px;
                        }
                        .banner-caption h1 {
                            font-size: 46px;
                        }
                        .banner-caption h1 br {
                            display: none;
                        }
                        .category-name {
                            font-size: 16px;
                        }
                    }
                    
                    @media (max-width: 1200px) {
                        .product-banner-image {
                            height: 438px;
                            width: 100%;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        .sidebar-item {
                            position: unset;
                        }
                    }
                    
                    @media (max-width: 768px) {
                        .slider-package-info {
                            position: absolute;
                            /*top: 0px;*/
                            bottom: 18px;
                            left: 20px;
                            margin-left: 12%;
                        }
                        .banner-caption h2 {
                            font-size: 23px;
                        }
                        .banner-caption h1 {
                            font-size: 43px;
                        }
                    }

                    .menu-theme{
                        background-color: #a52a2a;
                        padding-bottom: 0px;
                      
                    }

                    

                  
                        /*Media Queries */
                        @media (max-width: 992px) {
                            .banner-caption h2 {
                                font-size: 25px;
                            }
                            .banner-caption h1 {
                                font-size: 46px;
                            }
                            .banner-caption h1 br {
                                display: none;
                            }
                            .category-name {
                                font-size: 16px;
                            }
                            .activeSuperTabs{
                                /* background-color: burlywood !important; */
                                border: none !important;
                            }
                        }
                        @media (max-width: 768px) {
                            .slider-package-info {
                                position: absolute;
                                /*top: 0px;*/
                                bottom: 18px;
                                left: 20px;
                                margin-left: 12%;
                            }
                            .banner-caption h2 {
                                font-size: 23px;
                            }
                            .banner-caption h1 {
                                font-size: 43px;
                            }
                            .banner-caption h1 br {
                                display: none;
                            }
                            .category-name {
                                font-size: 16px;
                            }

                            .category-name {
                                font-size: 11px;
                               
                                
                               
                                margin: 0px -2px ;
                            }

                            .category-detail-m{
                                display: unset;
                            }

                            .category-detail-d{
                                display: none;
                            }

                            .search-component {
                                margin-top: 20px;
                                margin-bottom:5px;
                            }

                            .category-group {
                                margin-bottom: 10px;
                               
                            }

                            input.search-input {
                                border-radius: 0px;
                                height: 40px;
                                position: relative;
                                border: 1px solid #666;
                                font-size: 15px;
                                padding-left: 15px;
                            }

                            .search-icon {
                                position: absolute;
                                width: 45px;
                                height: 45px;
                                text-align: center;
                               
                                font-size: 28px !important;
                                right: 20px;
                                top: 8px;
                                color: #525252;
                            }

                            .get-in-touch-top-padding {
                                padding-top: 0px;
                            }

                            .product-detail-container {
                                padding: 10px;
                            }

                            .product-overview-title{
                                font-size: 18px;
                                margin-bottom: 0px;
                                margin-top: 20px;
                            }
                            .product-sub-p{
                                font-size: 16px;
                            }
                            .product-description-p{
                                margin-bottom: 30px;
                            }
                            .product-overview-title2{
                                margin-bottom: 20px;
                            }

                            .carousel-img {
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: cover;
                                height: 300px;
                            }

                            .brand-logo{
                                height: 40px;
                                
                            }



                        }
                        @media (max-width: 576px) {
                            .slider-package-info h3 {
                                font-size: 27px;
                            }
                            .banner-caption h2 {
                                font-size: 21px;
                            }
                            .banner-caption h1 {
                                font-size: 33px;
                            }
                            .banner-caption h1 br {
                                display: none;
                            }
                            .companyname {
                                display: none;
                            }
                        }
                    
                    /*Media Queries End*/
.carousel .slide iframe{
    /* width: 40% !important; */
    height: 400px !important;
    margin-top: 2rem;
    
}
.background-img:before{
    background: rgba(54, 46, 46, 0.85);
}
.video-frame{
    border-radius: 20px;
    margin-top: 20px !important;
}

.btn-div{
    text-align: left;
    background-color: brown !important;
    color: #ffffff;
    font-size: 15px;
}
.btn-div a{
    text-align: left;
    background-color: brown;
    color: #ffffff;
    font-weight: bold;
    border: white 1px solid;
}
.btn-div a:hover{
    text-align: left;
    background-color: brown !important;
    color: #ffffff;
    font-weight: bold;
    border-top: #ffffff 5px solid !important;
    border-left: #ffffff 5px solid !important;
}
.activeTabs{
    text-align: left;
    background-color: brown !important;
    color: #ffffff;
    font-weight: bold;
    border-top: #ffffff 5px solid !important;
    border-right: #ffffff 5px solid !important;
}
.activeSubTabs{
    /* background-color: burlywood !important; */
    font-weight: bolder;
    padding: .5rem !important;
    border-bottom: burlywood 5px solid !important;
    border-top: burlywood 5px solid !important;
    background-color: gold !important;
    border-top: #ffffff 5px solid !important;
    border-left: #ffffff 5px solid !important;
    border-bottom: #ffffff 5px solid !important;
}
.activeSuperTabs{
    /* background-color: burlywood !important; */
    font-weight: bolder;
    padding: .5rem !important;
    border-bottom: #E8C8C8 3px solid !important;
    border-bottom-width: 7px !important;
}
.navbar-nav{
    font-size: 15px;
}
.activeSubTabs{
    background-color: burlywood !important;
    font-weight: bold;
    padding: .5rem !important;
}
.navbar-nav{
    font-size: 15px;
}
.white-space{
    margin-top: 90px;
}

.Button-press {
    margin-top: 10px;
    color: black;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    font-size: 16px !important;
    font-weight: 500;
    /* background-color: black !important; */
    border: none !important;
    box-shadow: none !important;
}
#nav-menu{
    -webkit-flex-direction: column;
            flex-direction: column;
}
.sub-item{
    padding-left: 10px;
}
.Button-press:hover{
    color: rgba(0,0,0,.7);
}
.btn-light {
    background-color: none;
}
.Button-press:hover{
    color: rgba(0,0,0,.7);
}
.btn-light {
    background-color: none;
}
.navlinkclass {
    /* margin-top: 5px; */
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: black;
    width: 100%;
}

.navBarToggle{
    margin-left: 20px;
}

ol {
    text-align: left;
}
.Menu {
    margin-left: 1rem;
    font-size: 1.5rem;
    background: none;
}
.Sidebar-Menu {
    width: 250px;
    height: 600px;
    margin-left: 2rem;
    margin-bottom: 10px;
    border: 1px solid gray;
    border-radius: 10px;
    overflow-y: auto;

}

.navMenu.active {
    left: 0;
    transition: 500ms;
}

.nav-menu {
    background: none;
    width: 250px;
    /* height: 100vh; */
    display: -webkit-flex;
    display: flex;
    position: fixed;
    /* top: 3rem; */
    left: -100%;
    transition: 10s;
    -webkit-justify-content: center;
            justify-content: center;
}

.vertical-menu {
    width: 250px;
    height: 600px;
    /* margin-left: 2rem; */
    border: 1px solid gray; 
    overflow-y: auto;
  }
  
  .vertical-menu a {
    display: block;
    padding: 12px;
    text-decoration: none;
  }
  
  .vertical-menu a:hover {
    background-color:burlywood;
  }
  
  .vertical-menu a.active {
    background-color: #4CAF50;
    color: white;
  }
  .slick-dots li{
    display: inline-block;
    width: 2rem !important;
    height: 3rem !important;
    border-radius: 0.5rem;
    cursor: pointer;
    background: burlywood;
    color: white;
    margin: 0.2rem !important;
  }
  .slick-active{
    background: brown !important;
    font-weight: bolder;
  }
  .slick-dots{
    position: -webkit-sticky;
    position: sticky; 
  }
  
  .slick-next:before {
    font-size: 50px !important;
    color: burlywood  !important;

}
  .slick-prev:before {
    font-size: 50px !important;
    color: burlywood !important;

}

@media (max-width: 992px) {

    .activeSuperTabs{
        /* background-color: burlywood !important; */
        border: none !important;
   
        padding: 0px !important;
    }
}
.place-IdCard-comp{
    width:100%;
    margin: auto;
}
.prof-title{
    font-size: 2rem;
    color: #ee7c7c;
    padding: 10px;
}
.more-content{
    font-weight: bold;
    text-decoration: none;
    color: black;
}

.idcard-details{
    text-align: justify;
    padding: 10px;
    font-size: 13px;
}
.img-card {
    height: 15rem;
}
.img-card-prof{
    height: 327px
}
.prof-title {
    font-size: 1.5rem;
    font-weight: bolder;
    color: gray;
}
.employeecard-image-3{
    border-radius: 0;
}
.employeecard-desk {
    background: white;
    margin:10px auto;
    height: 150px;
    background-size: 160px 160px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: 3px solid #565656;
    width: 150px;
}
.thumb{
    height: 50px;
}
.thumbs-wrapper{
    margin: 0px !important;
    margin-left: 3rem !important;
    margin-bottom: 2rem !important;
}
.slider-overlay-carousel {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:burlywood;
    color: brown;
    font-weight: bolder;
}
.carousel .control-dots .dot{
    box-shadow: 0px 0px 0px rgb(0 0 0 / 90%) !important;
    background: none;
}
.place-IdCard-comp{
    width:100%;
    margin: auto;
}
.prof-title{
    font-size: 2rem;
    color: #ee7c7c;
    padding: 10px;
}
.prof-title-comp{
    font-size: 2rem;
    color: #ee7c7c;
    padding: 10px;
}
.more-content{
    font-weight: bold;
    text-decoration: none;
    color: black;
}

.idcard-details{
    text-align: justify;
    padding: 10px;
    font-size: 13px;
}
.img-card {
    height: 17rem;
}
.img-card-prof{
    height: 300px;
}
.prof-title {
    font-size: 1.5rem;
    font-weight: bolder;
    color: gray;
}
.employeecard-image-3{
    border-radius: 0;
}
.employeecard-desk {
    background: white;
    margin:10px auto;
    height: 150px;
    background-size: 160px 160px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: 3px solid #565656;
    width: 150px;
}
.thumb{
    height: 50px;
}
.thumbs-wrapper{
    margin: 0px !important;
    margin-left: 3rem !important;
    margin-bottom: 2rem !important;
}
.carousel .slider-wrapper{
    margin-top: 0px;
}
.slider-overlay-carousel {
    position: relative;
    top: -14px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:#a52a2a;
    color: #fff;
    border-radius: 20px;
    font-weight: bolder;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.slider-overlay-carousel-comp {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:brown;
    color: #ffffff;
    font-weight: bolder;
    width: 100%;
}
.carousel .control-dots .dot{
    box-shadow: 0px 0px 0px rgb(0 0 0 / 90%) !important;
    background: none;
}

.news-element{
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    
}
.news-border{
    border-color: #53607a;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;

}

.plr{
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 90px;
}
.plr2{
    padding-left: 60px;
    padding-right: 60px;
}
.col-xl-4{
    padding-left: 0px !important;
}
.linkaffairs{
    padding-left: 10px !important;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.linkaffairs:hover{
    padding-left: 10px !important;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.background-img {
    position: relative;
    width: 100%;
    height: 50rem;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.phone-grid {
  grid-column: 2/3;
  background-color: #fff;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(38rem, 1fr));
  grid-gap: 1.5rem; }
  @media screen and (min-width: 1600px) {
    .phone-grid {
      grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr)); } }

.pagination {
  grid-column: 2/3;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center; }
  .pagination .tab {
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2); }
  .pagination ul {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden; }
    .pagination ul li {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      height: 100%;
      width: 20rem;
      background-color: #fff;
      list-style: none;
      cursor: pointer;
      position: relative; }
      .pagination ul li:active {
        color: #43ABC9; }
        .pagination ul li:active:nth-of-type(1) ~ .bar {
          left: 0rem; }
        .pagination ul li:active:nth-of-type(2) ~ .bar {
          left: 10rem; }
        .pagination ul li:active:nth-of-type(3) ~ .bar {
          left: 20rem; }
        .pagination ul li:active:nth-of-type(4) ~ .bar {
          left: 30rem; }
        .pagination ul li:active:nth-of-type(5) ~ .bar {
          left: 40rem; }
        .pagination ul li:active:nth-of-type(6) ~ .bar {
          left: 50rem; }
        .pagination ul li:active:nth-of-type(7) ~ .bar {
          left: 60rem; }
        .pagination ul li:active:nth-of-type(8) ~ .bar {
          left: 70rem; }
        .pagination ul li:active:nth-of-type(9) ~ .bar {
          left: 80rem; }
        .pagination ul li:active:nth-of-type(10) ~ .bar {
          left: 90rem; }
        .pagination ul li:active:nth-of-type(11) ~ .bar {
          left: 100rem; }
        .pagination ul li:active:nth-of-type(12) ~ .bar {
          left: 110rem; }
        .pagination ul li:active:nth-of-type(13) ~ .bar {
          left: 120rem; }
        .pagination ul li:active:nth-of-type(14) ~ .bar {
          left: 130rem; }
        .pagination ul li:active:nth-of-type(15) ~ .bar {
          left: 140rem; }
        .pagination ul li:active:nth-of-type(16) ~ .bar {
          left: 150rem; }
        .pagination ul li:active:nth-of-type(17) ~ .bar {
          left: 160rem; }
        .pagination ul li:active:nth-of-type(18) ~ .bar {
          left: 170rem; }
        .pagination ul li:active:nth-of-type(19) ~ .bar {
          left: 180rem; }
        .pagination ul li:active:nth-of-type(20) ~ .bar {
          left: 190rem; }
    .pagination ul .active {
      color: #43ABC9; }
      .pagination ul .active:nth-of-type(1) ~ .bar {
        left: 0rem; }
      .pagination ul .active:nth-of-type(2) ~ .bar {
        left: 10rem; }
      .pagination ul .active:nth-of-type(3) ~ .bar {
        left: 20rem; }
      .pagination ul .active:nth-of-type(4) ~ .bar {
        left: 30rem; }
      .pagination ul .active:nth-of-type(5) ~ .bar {
        left: 40rem; }
      .pagination ul .active:nth-of-type(6) ~ .bar {
        left: 50rem; }
      .pagination ul .active:nth-of-type(7) ~ .bar {
        left: 60rem; }
      .pagination ul .active:nth-of-type(8) ~ .bar {
        left: 70rem; }
      .pagination ul .active:nth-of-type(9) ~ .bar {
        left: 80rem; }
      .pagination ul .active:nth-of-type(10) ~ .bar {
        left: 90rem; }
      .pagination ul .active:nth-of-type(11) ~ .bar {
        left: 100rem; }
      .pagination ul .active:nth-of-type(12) ~ .bar {
        left: 110rem; }
      .pagination ul .active:nth-of-type(13) ~ .bar {
        left: 120rem; }
      .pagination ul .active:nth-of-type(14) ~ .bar {
        left: 130rem; }
      .pagination ul .active:nth-of-type(15) ~ .bar {
        left: 140rem; }
      .pagination ul .active:nth-of-type(16) ~ .bar {
        left: 150rem; }
      .pagination ul .active:nth-of-type(17) ~ .bar {
        left: 160rem; }
      .pagination ul .active:nth-of-type(18) ~ .bar {
        left: 170rem; }
      .pagination ul .active:nth-of-type(19) ~ .bar {
        left: 180rem; }
      .pagination ul .active:nth-of-type(20) ~ .bar {
        left: 190rem; }
    .pagination ul .bar {
      width: 10rem;
      background-color: #43ABC9;
      height: 5px;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: 0.5s ease; }

.phone-item {
  height: 45rem;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  color: #999;
  margin-top: 2rem; }
  .phone-item--box {
    width: 100%;
    height: 80%;
    border-bottom: 1px solid rgba(153, 153, 153, 0.3);
    padding-top: 10px;
    -webkit-align-items: center;
            align-items: center; }
  .phone-item--img {
    width: 100%;
    height: 30rem;
    object-fit: contain;
    background-color: black; }
  .phone-item--info {
    padding: 1rem 1.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .phone-item .textTitle {
    display: block;
    -webkit-justify-content: center;
            justify-content: center;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 2.0rem;
    font-weight: 500;
    color: #000000;
    margin: auto; }
  .phone-item .textTitleDiv {
    text-align: center;
    padding-right: 0rem;
    padding-left: 0rem; }
  .phone-item .flex-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .phone-item .flex-row .title {
      font-size: 1.4rem;
      font-weight: 500;
      -webkit-flex: 1 1;
              flex: 1 1;
      padding: 0px;
      margin: auto; }
    .phone-item .flex-row .price {
      font-weight: 500;
      font-size: 1.4rem;
      margin-left: -50%; }
  .phone-item .description {
    font-size: 1.4rem;
    width: 80%; }
  .phone-item .buttonsViewAll {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 1rem; }
    .phone-item .buttonsViewAll .more-info {
      width: 80%;
      border: none;
      height: 4rem;
      background-color: #000000;
      cursor: pointer;
      outline: none;
      color: #fff;
      font-size: 1.7rem;
      margin: 0 .5rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      text-decoration: none;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }

.overview-body-content {
    margin-left: 40px;
    font-size: 14px;
    font-size: 14px;
    text-transform: capitalize;

}
.faculty-name{
    font-size: 3rem;
    font-weight: bolder;
}
.overview-row1{
    border: 1px solid rgb(0, 0, 0);
    -webkit-align-items: center;
            align-items: center;
    
}
.overview-col1{
    /* height: 50px; */
    font-weight: 900;
    font-size: 17px;
    color: black;
    background-color: antiquewhite;
    border: 1px solid black;
}

.data-col{
    border: 1px solid black;
    padding: 5px;
}
.contentDiv {
  text-align: center;
  padding-right: 0rem;
  padding-left: 0rem; }

.contentDivSearch {
  margin-top: 2rem; }

.headerTitle {
  font-family: "Trebuchet MS", sans-serif;
  font-size: 3.5em;
  letter-spacing: 0.2px;
  font-weight: bold; }

.buttonContent a:link,
.buttonContent a:visited {
  background-color: white;
  color: black;
  font-size: 2.0em;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: none; }

.buttonContent a:hover,
.buttonContent a:active {
  background-color: #000000 !important;
  color: white !important; }

.searchContentIcon {
  border: black .007rem solid;
  border-left: none;
  text-align: right;
  vertical-align: middle;
  font-size: 2.5rem;
  padding: 0px;
  margin: 0px; }
  .searchContentIcon svg {
    color: gray;
    padding: 0px;
    margin: 0px; }
  .searchContentIcon svg:hover,
  .searchContentIcon a:active {
    color: #000000; }

.searchContentInput {
  border: #000000 solid .1rem;
  border-right: none; }
  .searchContentInput svg {
    color: gray; }
  .searchContentInput svg:hover,
  .searchContentInput a:active {
    color: #000000; }

.carousel .thumb {
  height: 60px;
  width: 100px; }

.imgThubnailRoll {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block; }

.carousel.carousel-slider .control-arrow:hover {
  background: none; }

.menu-item > .sub-menu > a {
  text-decoration: none; }

li {
  margin-left: 0px !important; }

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #f44646; }

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #ff2f2f; }

.img-card{
    margin: auto;
    height: 400px;
    width: 600px;
}

.slider-content{
    width: 650px;
    height: 450px;
    margin: auto;
}
.slick-next{
    margin: -15px;
}
.slick-prev{
    margin: -15px;

}

.place-Card {
  text-align: center;
  border-radius: 10px;
  margin-left: 40%;
  width: 300px;
  box-shadow: -5px 2px 18px 4px #ccc;
}
.Card-Img {
  position: relative;
  top: -50px;
  left: 29%;
  width: 45%;
  /* height: 30%; */
  border-radius: 50%;
}
.cover-photo {
  background: brown;
  width: 300px;
  height: 80px;
}

.para {
  font-size: small;
  font-family: sans-serif;
  text-align: justify;
}
.name {
  background-color: brown;
  color: aliceblue;
  padding: 8px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}
.pro-name {
  font-size: large;
}
.vission {
  background-color: brown;
  color: aliceblue;
  padding: 5px;
  border-radius: 5px;
}
.vission-main-title {
  background-color: brown;
  color: aliceblue;
  padding: 20px;
  border-radius: 5px;
}
.mission {
  background-color: brown;
  color: aliceblue;
  padding: 5px;
}
.TAPO {
  background-color: brown;
  color: aliceblue;
  padding: 8px;
}
.place-Card-Banner {
  text-align: center;
  border-radius: 10px;
  width: 1380px;
  box-shadow: -5px 2px 18px 4px #ccc;
}
.employeecard-image-banner {
  height: 300px;
  background-size: 1355px 300px;
  background-position: 50% 50%;
  border-radius: 0px;
  border: 5px solid #565656;
  width: 1355px;
}
.place-IdCard{
    width:98%;
    margin: auto;
    box-shadow: 0 0 10px #f8b153;
}
.prof-title{
    font-size: 2rem;
    color: #ee7c7c;
    padding: 10px;
}
.more-content{
    font-weight: bold;
    text-decoration: none;
    color: black;
}
 a:hover{
    color: #0e0d0d;
    cursor: pointer;
    text-decoration: none;
}
.idcard-details{
    text-align: justify;
    padding: 10px;
    font-size: 13px;
}
.employeecard-image-3{
    border-radius: 0;
}
.employeecard-desk {
    background: white;
    margin:10px auto;
    height: 150px;
    background-size: 160px 160px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: 3px solid #565656;
    width: 150px;
    border-radius: 5px;
}
.img-card{
    margin: auto;
    height: 400px;
    width: 600px;
}

.slider-content{
    width: 650px;
    height: 450px;
    margin: auto;
}
.slick-next{
    margin: -15px;
}
.slick-prev{
    margin: -15px;

}

.img-card{
    margin: auto;
    height: 400px;
    width: 600px;
}

.slider-content{
    width: 650px;
    height: 450px;
    margin: auto;
}
.slick-next{
    margin: 8rem !important;
    top: 38%;
    position: absolute;
    z-index: 1;
}
.slick-prev{
    margin: 8rem !important;
    top: 38%;
    position: absolute;
    z-index: 1;
}
.slick-slider{
    margin: auto;
}



.img-card{
    margin: auto;
    height: 400px;
    width: 600px;
}

.slider-content{
    width: 650px;
    height: 450px;
    margin: auto;
}
.slick-next{
    margin: -15px;
}
.slick-prev{
    margin: -15px;

}

.TabsContainer {
    padding-top: 5px;
    
}
.TabsContainerParent {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray !important;
}
.TabsHeaderContainer {
    border-bottom:  1px solid gray;
}
.react-tabs{
    margin-left: 0.5rem;
}
.navbar-light .navbar-toggler{
    margin: 0.5rem;
}
/* .col-lg-2{
    padding-left: 0;
} */
.heading-top{
    margin-top: 20px;
}
h1,h2,h3,h4,h5,h6{
    margin-bottom: .5rem;
    font-weight: 700;
    line-height: 1;
}
.main-logo{
    height: 150px;
    float: left;
    margin-left: 20px;
    padding: 0;
    box-shadow: 0 0 10px black;
    border-radius: 50%;
    
}
.logo{
    color: red;
    text-align: center;
    margin: 0;
    font-size: 30px;
}

.sub-heading{
    text-align: center;
    font-size: 28px;
    color: red;
    
}
.sub-heading1{
    text-align: center;
    font-size: 18px;
    color:rgb(241, 177, 15)
}
.sub-heading2{
    text-align: center;
    font-size:23px ;
}
button:hover {
    border: none;
    color: black;
}
.admission-content{
    margin-top: 5.5rem;
}
.office_use{
    font-size: 15px;
    text-align: center;
}
.add-input-first{
    border: none;
    border-bottom: 2px solid black;
    outline: none;
}
.container_content{
    text-align: center;
}
.for_office_useOnly{
    margin-left: 20px;
}
.signature{
    text-align: center;
    margin-right: -1rem;
    margin-top: -24px;
    margin-left: 65%;
    font-size: 15px;
    
}
.office_content{
    margin: 2rem;
    margin-right: 29rem;
    border: 2px solid black;
    margin-left: 5rem;
    width: 70%;
    height: 290px;
   
}
.pic{
    float: right;
    margin-right: 65px;
    margin-top: 45px;
   border: 2px solid black;
   height: 200px;
   width: 150px;
   text-align: center;
   padding: 30px;
   background-color: rgba(112, 153, 42, 0.616);;
}
/* --------------------First Page CSS------------------------ */
.table-admission{
    font-size: 1rem;
}
.add-label{
    font-size: 2rem;
    padding: 10px;
    text-align: center;
}
.Header-content{
    background-color: rgba(112, 153, 42, 0.616);
    padding: 10px;
}
.input-full{
    border: none;
    border-bottom: 2px solid black;
    padding-top: 10px;
    outline: none;
}
.admission{
    background-color: #a4c561;
}

.letter{
    margin-left: 100px;
    font-size: 15px;
}
.type-of{
    font-size:2rem ;
}

.header_content  h2{
       margin-top: -1rem;        
}
.Header-content{
  background-color: rgba(112, 153, 42, 0.616);
  padding: 10px;
}
.adm-form {
    text-align: justify;
    font-size: 2rem;
  }
  .dual-data{
    border: 1px solid;
    text-align: center;
    font-size: 12px;
  }
  .second-Head {
    float: right;
    margin-right: 40px;
    font-size: 2rem;
  }
  .sig-parent {
    float: left;
    font-family: cursive;
    margin-left: 20px;
  }
  .sig-student {
    float: right;
    font-family: cursive;
    margin-right: 20px;
  }
  .dotted {
    border-bottom: 2px dashed black;
    width: 100%;
  }
  .fee-p {
    text-align: center;
    margin-top: 20px;
    font-size: x-large;
  }
  .fee-table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
  }
  .fee-tableHead {
    /* background-color: rgba(132, 170, 67, 0.616); */
    border: 1px solid black;
  }
  .fee-tableData {
    border: 1px solid black;
    padding: 15px;
  }
  .table-input {
    border: none;
    border-bottom: none;
    outline: none;
  }
  .sub-head {
    float: right;
  }
  .note-content{
    font-size: 2rem;
  }
.admission_table_two{
    font-size: 1rem;
}
.Header-content{
    background-color: rgba(112, 153, 42, 0.616);
    padding: 10px;
}
.add-input{
    border: none;
    border-bottom: 2px solid black;
    outline: none;
}

.para{
    font-size: 2rem;
    margin: 10px;
}


label{
    font-size: 2rem;
    padding: 10px;
    text-align: center;
}
.para_text{
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
}
.table_input{
    outline: none;
    border: none;
    text-align: center;
}

#table_border{
    
    border-spacing: none !important;
    margin:auto;
}
.table_row{
    border: 1px solid black;
    text-align: center;
    font-size: 16px;
}
.table_row1{
    width: 700px;
    border: 1px solid black;
    text-align: center;
    font-size: 16px;
    text-align: left;
    padding: 4px;
}

.Header-content{
    background-color: rgba(112, 153, 42, 0.616);
    padding: 10px;
}
.add-label{
    font-size: 2rem;
    padding: 10px;
    text-align: center;
}
.add-input{
    border: none;
    border-bottom: 2px solid black;
    padding-top: 10px;
    outline: none;
}

/* --------------------third------------------- */
.cet{
    text-align: center;
    font-weight: bolder;
}
.table-content{
    width: 700px;
    border: 1px solid black;
    margin: auto;
    
}
.table-content, th{
    font-size: 2rem;
    text-align: center;
}
.table-data{
    border: none;
    padding-top: 10px;
    outline: none;
    text-align: center;
}
.para{
    font-size: 2rem;
    margin: 10px;
}
.date-place{
    margin: 10px;
}
.signat{
    margin-left: 30px;
    float: right;
}
/* --------order list------------- */
.order , li{
    font-size: 2rem;
    margin-left: 20px;
}
.place-Card {
  text-align: center;
  border-radius: 10px;
  margin-left: 40%;
  width: 300px;
  box-shadow: -5px 2px 18px 4px #ccc;
}
.Card-Img {
  position: relative;
  top: -50px;
  left: 29%;
  width: 45%;
  /* height: 30%; */
  border-radius: 50%;
}
.cover-photo {
  background: brown;
  width: 300px;
  height: 80px;
}

.para {
  font-size: small;
  font-family: sans-serif;
  text-align: justify;
}
.name {
  background-color: brown;
  color: aliceblue;
  padding: 8px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}
.pro-name {
  font-size: large;
}
.vission {
  background-color: rgba(73, 42, 42, 0.85);
  color: aliceblue;
  padding: 5px;
  border-radius: 5px;
}
.para-heading{
  background: url(/static/media/College.7841749c.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}
.vission-main-title {
  /* background-color: rgba(59, 56, 56, 0.85); */
    background-color: rgba(59, 56, 56, 0.85);
  /* background-color: rgba(54, 46, 46, 0.85); */
  color: aliceblue;
  padding: 20px;
  border-radius: 5px;
}

  
.mission {
  background-color: brown;
  color: aliceblue;
  padding: 5px;
}
.TAPO {
  background-color: brown;
  color: aliceblue;
  padding: 8px;
}
.place-Card-Banner {
  text-align: center;
  border-radius: 10px;
  width: 1380px;
  box-shadow: -5px 2px 18px 4px #ccc;
}
.employeecard-image-banner {
  height: 300px;
  background-size: 1355px 300px;
  background-position: 50% 50%;
  border-radius: 0px;
  border: 5px solid #565656;
  width: 1355px;
}
body{
	line-height: 1.5;
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
}
body.hidden-scrolling{
	overflow-y: hidden;
}
*{
	margin:0;
	box-sizing: border-box;
}
ul{
	list-style: none;
	margin:0;
	padding:0;
}
.menu-item > .sub-menu > a{
	text-decoration: none;
}
li {
    font-size: 2rem;
}
/*header*/


.menu-item:hover{
	background-color: rgba(117, 114, 114, 0.5);
}

.menu-item{
	display: inline-block;
	position: relative;
}
.menu-item > a{
	display: block;
	padding: 12px 0 5px 5px;
	font-size: 16px;
	color: #000000;
	text-transform: capitalize;
	font-weight: 600;
	transition: all 0.3s ease;
	text-decoration: none;
}
.spaceTopMenu{
	margin-right: 30px;
}
.menu-item > a .plus:before,
.menu-item > a .plus:after{
	content:'';
	position: absolute;
	box-sizing: border-box;
	left: 50%;
	top:50%;
	background-color: #000000;
	height: 2px;
	width: 100%;
	-webkit-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
	transition: all 0.3s ease;
}
/* .menu-item > a .plus:after{
   transform: translate(-50%,-50%) rotate(-90deg);	
} */
.menu-item > .sub-menu > .menu-item > a:hover,
.menu-item:hover > a{
	color: orangered;
}
.menu-item > .sub-menu > .menu-item > a{
	color: #000000;
	text-align: center;
}

.menu-item > .sub-menu{
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
	width: 200px;
	position: absolute;
	left:0;
	top:100%;
	background-color: #ffffff;
	padding: 10px 0;
	-webkit-transform: translateY(10px);
	        transform: translateY(10px);
	transition: all 0.3s ease;
	opacity:0;
	visibility: hidden;
}

.menu-item > .sub-menu > .menu-item{
	display: block;
}
.dropdown-content{
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.showPopup{
	display: block;
}
.noShowPopup{
	display: none;
}
.menu-item > .sub-menu > .menu-item > a{
	display: block;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 600;
	color: #000000;
	transition: all 0.3s ease;
	text-transform: capitalize;
}

/* responsive */

@media(max-width: 991px){
	.header .menu-overlay.active{
	visibility: visible;
	opacity: 1;
}
	.header .nav-menu{
		position: fixed;
		right: -280px;
		visibility: hidden;
		width: 280px;
		height: 100%;
		top:0;
		overflow-y: auto;
		background-color: white;
		z-index: 1000;
		padding: 15px 0;
		transition: all 0.5s ease;
	}
	.header .nav-menu.open{
		visibility: visible;
		right: 0px;
	}
	.header .menu > .menu-item{
		display: block;
		margin:0;
		color: #000000;
	}
	.menu-item-has-children > a{
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
	}
.menu-item > a{
		color: black;
		/* padding: 12px 15px; */
		/* border-bottom: 1px solid #333333; */
	}
	.header .menu > .menu-item:first-child > a{
	    border-top: 1px solid #333333;	
	}
	
	/* .header .menu > .menu-item > a .plus:before, 
	.header .menu > .menu-item > a .plus:after{
		background-color: black;
	} */
	/* .header .menu > .menu-item-has-children.active > a .plus:after{
        transform: translate(-50%,-50%) rotate(0deg);
	} */
	.header .menu > .menu-item > .sub-menu{
		width: 100%;
		position: relative;
		opacity: 1;
		visibility: visible;
		border:none;
		background-color: transparent;
		box-shadow: none;
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
		padding: 0px;
		left: auto;
		top:auto;
		max-height: 0;
		overflow: hidden;
	}
	.header .menu > .menu-item > .sub-menu > .menu-item > a{
		padding: 12px 45px;
		color: black;
		border-bottom: 1px solid #333333;
	}
	.header .close-nav-menu,
	.header .open-nav-menu{
		display: -webkit-flex;
		display: flex;
	}
}


/* .navclass{
    color: white !important;
} */
.brand-name{
    height: 100px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 10px;
}
.brand{
    display: -webkit-flex;
    display: flex;
}
.png-logo{
   position:absolute;
   top: 0;
   right: 5%;
   padding: 10px;
   -webkit-animation: logomove 5s  ;
           animation: logomove 5s  ;
   animation-direction: alternate-reverse; 
}
.brand-name:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
}
@-webkit-keyframes logomove {
    from {right: 50px;}
    to {right: 200px;}
  }
@keyframes logomove {
    from {right: 50px;}
    to {right: 200px;}
  }

.phone-grid {
  grid-column: 2/3;
  background-color: #fff;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(38rem, 1fr));
  grid-gap: 1.5rem; }
  @media screen and (min-width: 1600px) {
    .phone-grid {
      grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr)); } }

.pagination {
  grid-column: 2/3;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center; }
  .pagination .tab {
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2); }
  .pagination ul {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden; }
    .pagination ul li {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      height: 100%;
      width: 20rem;
      background-color: #fff;
      list-style: none;
      cursor: pointer;
      position: relative; }
      .pagination ul li:active {
        color: #43ABC9; }
        .pagination ul li:active:nth-of-type(1) ~ .bar {
          left: 0rem; }
        .pagination ul li:active:nth-of-type(2) ~ .bar {
          left: 10rem; }
        .pagination ul li:active:nth-of-type(3) ~ .bar {
          left: 20rem; }
        .pagination ul li:active:nth-of-type(4) ~ .bar {
          left: 30rem; }
        .pagination ul li:active:nth-of-type(5) ~ .bar {
          left: 40rem; }
        .pagination ul li:active:nth-of-type(6) ~ .bar {
          left: 50rem; }
        .pagination ul li:active:nth-of-type(7) ~ .bar {
          left: 60rem; }
        .pagination ul li:active:nth-of-type(8) ~ .bar {
          left: 70rem; }
        .pagination ul li:active:nth-of-type(9) ~ .bar {
          left: 80rem; }
        .pagination ul li:active:nth-of-type(10) ~ .bar {
          left: 90rem; }
        .pagination ul li:active:nth-of-type(11) ~ .bar {
          left: 100rem; }
        .pagination ul li:active:nth-of-type(12) ~ .bar {
          left: 110rem; }
        .pagination ul li:active:nth-of-type(13) ~ .bar {
          left: 120rem; }
        .pagination ul li:active:nth-of-type(14) ~ .bar {
          left: 130rem; }
        .pagination ul li:active:nth-of-type(15) ~ .bar {
          left: 140rem; }
        .pagination ul li:active:nth-of-type(16) ~ .bar {
          left: 150rem; }
        .pagination ul li:active:nth-of-type(17) ~ .bar {
          left: 160rem; }
        .pagination ul li:active:nth-of-type(18) ~ .bar {
          left: 170rem; }
        .pagination ul li:active:nth-of-type(19) ~ .bar {
          left: 180rem; }
        .pagination ul li:active:nth-of-type(20) ~ .bar {
          left: 190rem; }
    .pagination ul .active {
      color: #43ABC9; }
      .pagination ul .active:nth-of-type(1) ~ .bar {
        left: 0rem; }
      .pagination ul .active:nth-of-type(2) ~ .bar {
        left: 10rem; }
      .pagination ul .active:nth-of-type(3) ~ .bar {
        left: 20rem; }
      .pagination ul .active:nth-of-type(4) ~ .bar {
        left: 30rem; }
      .pagination ul .active:nth-of-type(5) ~ .bar {
        left: 40rem; }
      .pagination ul .active:nth-of-type(6) ~ .bar {
        left: 50rem; }
      .pagination ul .active:nth-of-type(7) ~ .bar {
        left: 60rem; }
      .pagination ul .active:nth-of-type(8) ~ .bar {
        left: 70rem; }
      .pagination ul .active:nth-of-type(9) ~ .bar {
        left: 80rem; }
      .pagination ul .active:nth-of-type(10) ~ .bar {
        left: 90rem; }
      .pagination ul .active:nth-of-type(11) ~ .bar {
        left: 100rem; }
      .pagination ul .active:nth-of-type(12) ~ .bar {
        left: 110rem; }
      .pagination ul .active:nth-of-type(13) ~ .bar {
        left: 120rem; }
      .pagination ul .active:nth-of-type(14) ~ .bar {
        left: 130rem; }
      .pagination ul .active:nth-of-type(15) ~ .bar {
        left: 140rem; }
      .pagination ul .active:nth-of-type(16) ~ .bar {
        left: 150rem; }
      .pagination ul .active:nth-of-type(17) ~ .bar {
        left: 160rem; }
      .pagination ul .active:nth-of-type(18) ~ .bar {
        left: 170rem; }
      .pagination ul .active:nth-of-type(19) ~ .bar {
        left: 180rem; }
      .pagination ul .active:nth-of-type(20) ~ .bar {
        left: 190rem; }
    .pagination ul .bar {
      width: 10rem;
      background-color: #43ABC9;
      height: 5px;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: 0.5s ease; }

.phone-item {
  height: 45rem;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  color: #999;
  margin-top: 2rem; }
  .phone-item--box {
    width: 100%;
    height: 80%;
    border-bottom: 1px solid rgba(153, 153, 153, 0.3);
    padding-top: 10px;
    -webkit-align-items: center;
            align-items: center; }
  .phone-item--img {
    width: 100%;
    height: 30rem;
    object-fit: contain;
    background-color: black; }
  .phone-item--info {
    padding: 1rem 1.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .phone-item .textTitle {
    display: block;
    -webkit-justify-content: center;
            justify-content: center;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 2.0rem;
    font-weight: 500;
    color: #000000;
    margin: auto; }
  .phone-item .textTitleDiv {
    text-align: center;
    padding-right: 0rem;
    padding-left: 0rem; }
  .phone-item .flex-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .phone-item .flex-row .title {
      font-size: 1.4rem;
      font-weight: 500;
      -webkit-flex: 1 1;
              flex: 1 1;
      padding: 0px;
      margin: auto; }
    .phone-item .flex-row .price {
      font-weight: 500;
      font-size: 1.4rem;
      margin-left: -50%; }
  .phone-item .description {
    font-size: 1.4rem;
    width: 80%; }
  .phone-item .buttonsViewAll {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 1rem; }
    .phone-item .buttonsViewAll .more-info {
      width: 80%;
      border: none;
      height: 4rem;
      background-color: #000000;
      cursor: pointer;
      outline: none;
      color: #fff;
      font-size: 1.7rem;
      margin: 0 .5rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      text-decoration: none;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }

.about-paragraph {
    color: #080707;
    font-size: 1.9rem;
    text-align: justify;
    padding:10px;
}
.contentDiv {
  text-align: center;
  padding-right: 0rem;
  padding-left: 0rem; }

.contentDivSearch {
  margin-top: 2rem; }

.headerTitle {
  font-family: "Trebuchet MS", sans-serif;
  font-size: 3.5em;
  letter-spacing: 0.2px;
  font-weight: bold; }

.buttonContent a:link,
.buttonContent a:visited {
  background-color: white;
  color: black;
  font-size: 2.0em;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: none; }

.buttonContent a:hover,
.buttonContent a:active {
  background-color: #000000 !important;
  color: white !important; }

.searchContentIcon {
  border: black .007rem solid;
  border-left: none;
  text-align: right;
  vertical-align: middle;
  font-size: 2.5rem;
  padding: 0px;
  margin: 0px; }
  .searchContentIcon svg {
    color: gray;
    padding: 0px;
    margin: 0px; }
  .searchContentIcon svg:hover,
  .searchContentIcon a:active {
    color: #000000; }

.searchContentInput {
  border: #000000 solid .1rem;
  border-right: none; }
  .searchContentInput svg {
    color: gray; }
  .searchContentInput svg:hover,
  .searchContentInput a:active {
    color: #000000; }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }

body {
  box-sizing: border-box; }

html::-webkit-scrollbar {
  width: 8px; }

html::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px; }

html::-webkit-scrollbar-track {
  background: #fff; }

.main-grid {
  font-size: 1.6rem;
  height: 100%;
  width: 100%;
  margin: 2rem 5rem;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-rows: 1fr 4.4rem;
  grid-template-columns: 20vw auto; }

.App {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

