
.news-element{
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    
}
.news-border{
    border-color: #53607a;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;

}

.plr{
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 90px;
}
.plr2{
    padding-left: 60px;
    padding-right: 60px;
}
.col-xl-4{
    padding-left: 0px !important;
}
.linkaffairs{
    padding-left: 10px !important;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.linkaffairs:hover{
    padding-left: 10px !important;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.background-img {
    position: relative;
    width: 100%;
    height: 50rem;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}