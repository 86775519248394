.carousel .slide iframe{
    /* width: 40% !important; */
    height: 400px !important;
    margin-top: 2rem;
    
}
.background-img:before{
    background: rgba(54, 46, 46, 0.85);
}
.video-frame{
    border-radius: 20px;
    margin-top: 20px !important;
}